import styled from 'styled-components';
import { Container } from '@components/global';

export const Headroom = styled.div`
  width: 100%;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  transition: top 0.5s;
  z-index: 9999;

  &.hidden {
    top: -80px;
  }
`;

export const Nav = styled.nav`
  background-color: ${props => props.theme.color.background.primary};
  box-shadow: ${props => props.theme.shadow.primary};
  width: 100%;
  top: 0;
  z-index: 1000;
  position: relative;
  transition: 0.4s;

  &.shrunk {
    padding: 2px 0;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 3em;
      `};
  }
`;

export const NavItem = styled.li`
  text-align: center;
  margin: 25px;
  font-family: ${props => props.theme.font.primary};
  ${props => props.theme.font_size.regular};

  a {
    text-decoration: none;
    font-weight: bold;
    color: ${props => props.theme.color.text.primary};
  }
`;

export const MobileMenu = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.color.background.primary};

  ${NavItem} {
    ${props => props.theme.font_size.larger};
  }
`;

export const Brand = styled.div`
  font-family: ${props => props.theme.font.primary};
  ${props => props.theme.font_size.large};

  a {
    line-height: 0;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    max-height: 45px;
    padding: 5px 0;
    width: 100%;
    display: flex;
    justify-content: left;
    margin-left: 0;
    padding: 14px 0;
  }
`;

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }

  ${props =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.md}) {
      display: none;
    }
  `}
`;

export const FollowLinksOuterWrapper = styled.div`
  /*position: absolute;*/
  width: 100%;
  bottom: 250px;
`;

export const FollowLinksWrapper = styled.div`
  width: 152px;
  margin: 0 auto;

  h2 {
    margin-bottom: 10px;
    text-align: center;
  }

  div {
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

export const MobileButton = styled.button`
  padding: 0;
  margin: 0;
  width: 64px;
  height: 64px;

  img {
    transition: 1s linear;
    width: 100%;
    padding: 13px;
  }
`;

export const Logo = styled.div`
  line-height: 1em;
  a {
    font-size: ${props => props.theme.font_size.base};
    color: ${props => props.theme.color.text.primary};
    text-decoration: none;
    font-weight: bold;
  }
`;
