const theme = {
  font: {
    primary: `'Helvetica Neue', 'Helvetica', sans-serif`,
    secondary: `'Helvetica Neue', 'Helvetica', sans-serif`,
  },
  font_size: {
    base: '18px',
    small: 'font-size: 0.77em; line-height: 1.400em',
    regular: 'font-size: 1em; line-height: 1.400em',
    inBetween: 'font-size: 1.111em; line-height: 2',
    large: 'font-size: 1.22em; line-height: 1.400em',
    larger: 'font-size: 1.55em; line-height: 1.400em',
    xlarge: 'font-size: 2.889em; line-height: 1.400em',
  },
  color: {
    text: {
      primary: '#1E2022',
      secondary: '#484B4D',
    },
    background: {
      primary: '#FFFFFF',
      secondary: '#F0F5F9',
    },
    accent: '#FFE66D',
  },
  shadow: {
    primary: '0 3px 6px rgba(0,0,0,0.16);',
    secondary: '0 1px 3px rgba(0,0,0,0.16);',
  },
  screen: {
    xxs: '374px',
    xs: '575px',
    sm: '767px',
    md: '820px',
    lg: '1199px',
    xl: '1919px',
  },
};

export default theme;
