import React from 'react';
import styled from 'styled-components';

import { Container } from '@components/global';
import Contacts from '@common/Contacts';
import MadeByYounai from '../common/MadeByYounai/MadeByYounai';

const Footer = () => (
  <FooterWrapper>
    <Container>
      <Contacts />
    </Container>
    <MadeByYounai />
  </FooterWrapper>
  
);

const FooterWrapper = styled.footer`
  background: ${props => props.theme.color.background.secondary};
  padding: 20px 0 0 0;
  margin-top: 45px;
`;


export default Footer;
