import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

import Button from '@common/Button';
import defaultImage from '@images/jsb.jpg';

const ProjectCard = ({ project }) => {
  const { excerpt } = project;
  const { slug } = project.fields;
  const { images, title, type } = project.frontmatter;
  return (
    <Card>
      <CardInner>
        {images ? (
          <Image fluid={images[0].childImageSharp.fluid} />
        ) : (
          <Img>
            <img src={defaultImage} alt="A placeholder house" />
          </Img>
        )}
        <Body>
          <Heading>{title}</Heading>
          <Type>{type}</Type>
          <Text>{excerpt}</Text>
          <Buttons>
            <Link to={`/projekt${slug}`}>
              <Button noMargin>Läs mer</Button>
            </Link>
          </Buttons>
        </Body>
      </CardInner>
    </Card>
  );
};

export default ProjectCard;

const Card = styled.div`
  background: ${props => props.theme.color.accent};
  box-shadow: ${props => props.theme.shadow.primary};
  position: relative;

  img {
    justify-self: center;
    display: flex;
  }
`;

const CardInner = styled.div`
  background: ${props => props.theme.color.background.secondary};
  position: relative;
  min-width: 300px;
  min-height: 530px;
  max-width: 313px;
  top: -8px;
  left: -8px;

  .gatsby-image-wrapper {
    height: 199px;
  }
`;

const Body = styled.div`
  padding: 0 20px;
  min-height: 350px;
`;

const Heading = styled.h3`
  margin: 14px 0 0 0;
`;

const Type = styled.p`
  margin-top: 0;
  font-weight: bold;
  opacity: 0.6;
`;

const Text = styled.p`
  min-height: 130px;
  margin: 0;
`;

const Buttons = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-self: flex-end;
`;

const Img = styled.div`
  height: 199px;
  overflow: hidden;
  img {
    max-width: 100%;
  }
`;
