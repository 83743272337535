import styled, { css } from 'styled-components';

const Button = styled.button`
  text-decoration: none;
  ${props => props.theme.font_size.regular};
  font-weight: bold;
  display: inline-block;
  padding: 15px 30px;
  margin: 2rem 0;
  background: transparent;
  color: ${props => props.theme.color.text.primary};
  min-width: 175px;
  text-align: center;
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  ${props =>
    props.primary &&
    css`
      border: 2px solid ${props => props.theme.color.accent};
      background: ${props => props.theme.color.accent};
      box-shadow: ${props => props.theme.shadow.primary};
      &:hover,
      &:focus {
        text-decoration: none;
        box-shadow: ${props => props.theme.shadow.secondary};
      }
    `}

  ${props =>
    props.noMargin &&
    css`
      margin: 0;
    `}
`;

export default Button;
