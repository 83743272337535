import React from 'react';
import styled from 'styled-components';

const Contacts = () => (
  <Wrapper>
    <Underline />
    <p>
      Johan Svanström <br />
      Byggkonsult AB <br />
      Svärdlångsvägen 27 <br />
      12060 Årsta
    </p>
    <p>
      Telefon: <ContactLink href="tel:+46704105542">070-410 55 42</ContactLink>{' '}
      <br />
      E-post:{' '}
      <ContactLink href="mailto:johan@svanstrombk.se">
        johan@svanstrombk.se
      </ContactLink>
    </p>
  </Wrapper>
);

const Wrapper = styled.div`
  color: ${props => props.theme.color.text.primary};
`;

const Underline = styled.div`
  width: 104px;
  height: 1px;
  margin: 20px 0;
  border-bottom: 5px solid ${props => props.theme.color.accent};
`;

const ContactLink = styled.a`
  color: ${props => props.theme.color.text.primary};
`;

export default Contacts;
