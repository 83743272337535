import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1199px;
  }

  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
`;

export const Section = styled.section`
  padding: 100px 0 0 0;
  overflow: hidden;

  &:nth-child(odd) {
    background: ${props => props.theme.color.background.secondary};
  }

  &:nth-child(even) {
    background: ${props => props.theme.color.background.primary};
  }

  ${props =>
    props.accent &&
    `background-color: ${
      props.accent === 'secondary'
        ? props.theme.color.background.secondary
        : props.theme.color.background.primary
    }`};
`;

export const SectionHeader = styled.h2`
  padding-top: 60px;

  @media (min-width: ${props => props.theme.screen.lg}) {
    margin-left: -30px;
  }

  @media (min-width: ${props => props.theme.screen.xl}) {
    margin-left: -60px;
  }
`;
