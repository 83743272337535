import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import ProjectCard from '@common/ProjectCard';

const ProjectList = ({ projects }) => (
  <Grid>
    {projects.map((project, i) => {
      if (i < projects.length - 1) {
        return (
          <Fade key={i}>
            <ProjectCard project={project} />
          </Fade>
        );
      }
      return null;
    })}
  </Grid>
);

export default ProjectList;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 50px 0;
  min-height: 571px;

  @media (min-width: ${props => props.theme.screen.lg}) {
    margin: 180px 0 50px 0;

    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    column-gap: 50px;
    row-gap: 90px;
  }
`;
