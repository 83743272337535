import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import { Squeeze } from 'react-burgers';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';

import Contacts from '@common/Contacts';
import { Container } from '@components/global';
import {
  Headroom,
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  Logo,
} from './style';

const NAV_ITEMS = [
  {
    name: 'Header',
    link: 'header',
  },
  {
    name: 'Tjänster',
    link: 'services',
  },
  {
    name: 'Projekt',
    link: 'projects',
  },
  {
    name: 'Om oss',
    link: 'about',
  },
  {
    name: 'Kontakt',
    link: 'contact',
  },
];

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
      prevScrollPos: 0,
      visible: true,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.setState({
      prevScrollPos: window.pageYOffset,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollPos } = this.state;
    const currentScrollPos = window.pageYOffset;

    function isVisible(currentScrollPos, prevScrollPos) {
      if (currentScrollPos < 56) {
        return true;
      } else if (currentScrollPos > 55 && prevScrollPos < currentScrollPos) {
        return false;
      }
      return true;
    }

    this.setState({
      prevScrollPos: currentScrollPos,
      visible: isVisible(currentScrollPos, prevScrollPos),
    });
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
    if (!this.state.mobileMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  /* change file */
  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
      document.body.classList.remove('no-scroll');
    }
  };

  getNavLink = item => {
    if (this.props.index) {
      return (
        <AnchorLink
          href={`#${item.link.toLowerCase()}`}
          onClick={this.closeMobileMenu}
        >
          {item.name}
        </AnchorLink>
      );
    } else {
      return (
        <a
          href={`/#${item.link.toLowerCase()}`}
          onClick={() => {
            this.closeMobileMenu();
            navigate(`/#${item.link.toLowerCase()}`);
          }}
        >
          {item.name}
        </a>
      );
    }
  };

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.link.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={100}
      >
        {NAV_ITEMS.map(navItem => {
          if (navItem.link !== 'header') {
            return (
              <NavItem key={navItem.name}>{this.getNavLink(navItem)}</NavItem>
            );
          } else {
            return null;
          }
        })}
      </Scrollspy>
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;
    return (
      <Headroom className={this.state.visible ? null : 'hidden'}>
        <Nav {...this.props}>
          <StyledContainer>
            <Brand>
              <Logo>
                <Link to="/" onClick={this.closeMobileMenu}>
                  Johan Svanström
                </Link>
              </Logo>
            </Brand>
            <Mobile>
              <Squeeze
                active={mobileMenuOpen}
                onClick={this.toggleMobileMenu}
                width={16}
                lineHeight={2}
                lineSpacing={4}
                color="#1E2022"
                customProps={{ 'aria-label': 'Mobile menu button' }}
              />
            </Mobile>
            <Mobile hide>{this.getNavList({})}</Mobile>
          </StyledContainer>
          <Mobile>
            {mobileMenuOpen && (
              <MobileMenu>
                <Container>{this.getNavList({ mobile: true })}</Container>
                <Container>
                  <Contacts />
                </Container>
              </MobileMenu>
            )}
          </Mobile>
        </Nav>
      </Headroom>
    );
  }
}

export default Navbar;
