import React from 'react';
import styled from 'styled-components';

const MadeByYounai = () => (
  <Wrapper>
    <h5>Designed and developed by</h5>
    <a href="https://younai.design/">
      <h6>Younai Design AB</h6>
    </a>
  </Wrapper>
);

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0;
  place-items: center;
  padding: 20px 0;
  background: ${props => props.theme.color.accent};
  ${props => props.theme.font_size.base};
  color: ${props => props.theme.color.text.primary};

  a,
  h5,
  h6  {
    color: ${props => props.theme.color.text.primary};
    display: block;
    margin: 0;
  }
`;

export default MadeByYounai;
